<template>
  <div>
    <meeting-room-preview
      v-if="isMeetingRoom"
      :workspaceID="dviewcontentWorkspace"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import MeetingRoomPreview from './MeetingRoomPreview'
export default {
  components: {
    MeetingRoomPreview,
  },
  data: () => ({
    isMeetingRoom: false,
  }),
  computed: {
    ...mapState('app',['locale', 'dviewcontentWorkspace']),
  },
  watch: {
    'dviewcontentWorkspace' (v) {
      if(v) this.handleGetContentTypes()
    },
  },
  mounted () {
    this.handleGetContentTypes()
  },
  methods: {
    handleGetContentTypes () {
      const url = `v1/internal/workspaces/${this.dviewcontentWorkspace}/sidebar`
      api.getAllWithoutLimit ('dviewcontent', url)
        .then(response => {
          this.isMeetingRoom = response.filter(x => x.icon === 'mdi-sign-real-estate').length > 0
        })
    },
  },
}
</script>

